import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import './styles/style.scss'
import utils from './utils/utils.js'
import kindeditor from '@/components/kindeditor.vue'

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';

import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';

// highlightjs
import hljs from 'highlight.js';

VMdPreview.use(githubTheme, {
  Hljs: hljs,
});

VMdEditor.use(githubTheme, {
  Hljs: hljs,
})

// 页面标题对应router里自定义的title
router.beforeEach((to,from,next)=>{
    // console.log(store.state.token)
    
    if(store.state.token || to.name == 'login' || to.name == 'register' || to.name == 'password' ){
      if(to.path=='/'){
        next('/service')
      }else{
        next();
      }
    }else{
      next({path: '/login', query: {redirect: to.path}})
    }
})

const app = createApp(App)

app.config.globalProperties.utils = utils

installElementPlus(app)

app.use(store).use(router).use(VMdPreview).component('kindeditor',kindeditor).mount('#app')
 