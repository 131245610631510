import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  { 
    path: "/login", 
    name: 'login',
    component: () => import('@/views/login/login.vue')
  },
  { 
    path: "/register", 
    name: 'register',
    component: () => import('@/views/login/register.vue')
  },
  { 
    path: "/password", 
    name: 'password',
    component: () => import('@/views/login/password.vue')
  },
  { 
    path: "/", 
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/service", 
        name: 'service',
        component: () => import('@/views/service/index.vue'),
        meta:{ titL: ['服务请求'] , tab:'/service' },
      },
      { 
        path: "/info/:id", 
        name: 'info',
        component: () => import('@/views/service/info.vue'),
        meta:{ titL: ['工单列表', '工单详情'] , tab:'/service', requiresAuth: true },
      },
      { 
        path: "/submit/tech", 
        name: 'submitTech',
        component: () => import('@/views/submit/tech.vue'),
        meta:{ titL: ['提交技术工单'] , tab:'/service' },
      },
      { 
        path: "/submit/untech", 
        name: 'submitUntech',
        component: () => import('@/views/submit/untech.vue'),
        meta:{ titL: ['提交非技术工单'] , tab:'/service' },
      },
      { 
        path: "/knowledge", 
        name: 'knowledge',
        component: () => import('@/views/knowledge/index.vue'),
        meta:{  tab:'/knowledge' },
      },
      { 
        path: "/search/:type/:id", 
        name: 'search',
        component: () => import('@/views/knowledge/search.vue'),
        meta:{ titL: ['知识库文档', '搜索'] , tab:'/knowledge' },
      },
      { 
        path: "/knowledgeInfo/:id", 
        name: 'knowledgeInfo',
        component: () => import('@/views/knowledge/info.vue'),
        meta:{ titL: ['知识库文档', '文档详情'] , tab:'/knowledge' },
      },
      { 
        path: "/account", 
        name: 'account',
        component: () => import('@/views/account/index.vue'),
        meta:{ titL: ['账号中心' ] , tab:'/account' },
      },
      { 
        path: "/csiInfo/:id", 
        name: 'csiInfo',
        component: () => import('@/views/account/csiInfo.vue'),
        meta:{ titL: ['账号中心', '绑定CSI详情' ] , tab:'/account' },
      },
      { 
        path: "/cgetel", 
        name: 'cgetel',
        component: () => import('@/views/account/cgetel.vue'),
        meta:{ titL: ['账号中心','手机换绑' ] , tab:'/account' },
      },
      { 
        path: "/cgemima", 
        name: 'cgemima',
        component: () => import('@/views/account/cgemima.vue'),
        meta:{ titL: ['账号中心','修改密码' ] , tab:'/account' },
      },
      { 
        path: "/news", 
        name: 'news',
        component: () => import('@/views/account/news.vue'),
        meta:{ titL: ['通知消息' ] , tab:'/account' },
      },
      { 
        path: "/newsinfo/:id/:type", 
        name: 'newsinfo',
        component: () => import('@/views/account/newsinfo.vue'),
        meta:{ titL: ['通知消息', '消息详情' ] , tab:'/account' },
      },
    ]
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
