import VueMarkdownEditor, { xss } from '@kangc/v-md-editor';

import CryptoJS from 'crypto-js/crypto-js';
const KEY = CryptoJS.enc.Utf8.parse('2523454dsfdsftyu'); // 'retedfgd' 与后台一致
const IV = CryptoJS.enc.Utf8.parse('2523454dsfdsftyu'); // 'asdqwe' 与后台一致

// AES加密 ：字符串 key iv  返回base64
export function Encrypt(str, keyStr, ivStr) {
    let key = KEY;
    let iv = IV;

    if (keyStr && ivStr) {
        key = CryptoJS.enc.Utf8.parse(keyStr);
        iv = CryptoJS.enc.Utf8.parse(ivStr);
    }
    let srcs = CryptoJS.enc.Utf8.parse(str);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        // mode: CryptoJS.mode.ECB,
        mode: CryptoJS.mode.CBC,  // mode 与后台一致
        padding: CryptoJS.pad.Pkcs7,
    });
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}

// AES 解密 ：字符串 key iv  返回base64
export function Decrypt(str, keyStr, ivStr) {
    let key = KEY;
    let iv = IV;

    if (keyStr) {
        key = CryptoJS.enc.Utf8.parse(keyStr);
        iv = CryptoJS.enc.Utf8.parse(ivStr);
    }
	
	if(str){
		let base64 = CryptoJS.enc.Base64.parse(str);
		let src = CryptoJS.enc.Base64.stringify(base64);

		var decrypt = CryptoJS.AES.decrypt(src, key, {
			iv: iv,
			// mode: CryptoJS.mode.ECB,
			mode: CryptoJS.mode.CBC, // 保持一致
			padding: CryptoJS.pad.Pkcs7
		});

		var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
	}
    return decryptedStr;
}

// 方法
export default {
    // 加载图片
	loadImg: function (imageName){
		return require(`@/assets/imgs/${imageName}`);
	},

    // 隐藏手机号中间数目
    hidephone: function(num){
        return num.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
    },
    formatRichText: function(html){
        
        let newContent = html.replace(/\<img/gi, '<img style="max-width:100%!important;height:auto;"');
      
        return newContent;  
    },

    markdonTxt: function(cont){

        if(!cont){
            return ''
        }
        // console.log(cont)

        let html = xss.process(VueMarkdownEditor.vMdParser.themeConfig.markdownParser.render(cont));

        // console.log(html);
        let str  

        str = html.replace(/(\n)/g, "");
        str = str.replace(/(\t)/g, "");
        str = str.replace(/(\r)/g, "");
        str = str.replace(/<\/?[^>]*>/g, "");
        str = str.replace(/\s*/g, "");
        str = str.replace(/<[^>]*>/g,"");
        // console.log(str);

        return str
    }

}